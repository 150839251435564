.slogan {
    height: 400px;
    background-image: url("../../../assets/foto1.jpeg"), url("../../../assets/foto2.jpeg"), url("../../../assets/foto4.jpeg");
    background-position: right top, left top, center center;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 35%, 35%, 35%;
}

@media (max-width: 576px) { 
    .slogan {
        background-image: url("../../../assets/foto4.jpeg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
