.know-more-content {
    padding-left: 250px;
    padding-right: 250px;
}

@media (max-width: 576px) { 
    .know-more-content {
        padding-left: 10px;
        padding-right: 10px;
    }    
}